import { render, staticRenderFns } from "./patients.page.vue?vue&type=template&id=7bad7165&scoped=true&"
import script from "./patients.page.vue?vue&type=script&lang=ts&"
export * from "./patients.page.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bad7165",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRangeSlider } from 'vuetify/lib/components/VRangeSlider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCol,VContainer,VDataTable,VRangeSlider,VRow,VSelect})
