import { ApiHttp } from "@/plugins/http.plugin";
import { AxiosInstance } from "axios";

export class PatientsRepository {
  private http: AxiosInstance;

  constructor() {
    this.http = ApiHttp;
  }

  async getList(filter: any = {}): Promise<any[]> {
    try {
      const response = await this.http.get("v1/customers/potential-risks", {
        params: {
          sex: filter.sex || null,
          score_min: filter.score[0] || null,
          score_max: filter.score[1] || null,
          group: filter.healthGroup,
          condition: filter.workingCondition,
        },
      });
      return response.data.data;
    } catch (e) {
      console.log("PatientsRepository.getList", e.response);
    }

    return [];
  }

  async getReferences(): Promise<any | null> {
    const result = {
      sexList: [{ id: null, name: "Все" }],
      healthGroups: [],
      workingConditions: [],
      scoreRange: { min: 0, max: 100 },
    };

    try {
      const { data } = await this.http.get("v1/customers/potential-risks/references");

      result.sexList = [...result.sexList, ...data.sex_list];
      result.healthGroups = data.health_groups;
      result.workingConditions = data.working_conditions;
      result.scoreRange = data.score_range;
    } catch (e) {
      console.log("PatientsRepository.getReferences", e.response);
    }

    return result;
  }
}
