




















































































import { parseProjectNameFromUrl } from "@/core/services/url.helpers";
import { PatientsRepository } from "@/modules/patients/patients.repository";
import { v4 as uuid } from "uuid";
import Vue from "vue";

export default Vue.extend({
  components: {},

  props: {},

  data() {
    return {
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "user_id",
        },
        { text: "ФИО", value: "fio" },
        { text: "Пол", value: "sex" },
        { text: "Возраст", value: "age" },
        { text: "Дата рождения", value: "birthday" },
        { text: "Score", value: "score" },
        { text: "Группа здоровья", value: "health_group" },
        { text: "Условия труда", value: "working_condition" },
        { text: "Болезнь", value: "icd10" },
      ],
      items: [] as any[],
      references: {
        sexList: [],
        healthGroups: [],
        workingConditions: [],
        scoreRange: { min: 0, max: 100 },
      },
      filters: {
        sex: null,
        score: [] as number[],
        healthGroup: [],
        workingCondition: [],
      },
    };
  },

  computed: {
    repo() {
      return new PatientsRepository();
    },

    projectName(): string {
      if (window.location.host.includes("localhost")) {
        return "rusagro";
      }

      return parseProjectNameFromUrl(window.location.host);
    },
  },

  async created() {
    this.references = await this.repo.getReferences();
    this.filters.score = [this.references.scoreRange.min, this.references.scoreRange.max];

    await this.loadList();
  },

  methods: {
    async loadList() {
      const items = await this.repo.getList(this.filters);
      this.items = items.map((item: any) => {
        let sex = "";

        if (item.sex === 1) {
          sex = "Женский";
        } else if (item.sex === 2) {
          sex = "Мужской";
        }

        return {
          ...item,
          uuid: uuid(),
          age: this.$date().diff(this.$date(item.birthday), "years"),
          sex: sex,
        };
      });
    },

    onRowClick(row) {
      window.open(
        `https://${this.projectName}.healthkeeper.ru/docs/?doctor_client_id=${row.user_id}#module=DoctorUserDocs`,
        "_blank",
      );
    },
  },
});
